<template>
  <v-app id="login">
    <v-main>
      <v-container fluid>
        <v-row class="align-center justify-center">
          <v-col xs="12" sm="12" md="6" lg="4">
            <v-card flat>
              <v-form autocomplete="off" @submit.prevent="login()">
                <v-card-title class="pt-12 text-center">
                  <img width="200" src="../assets/logo.png">
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="username"
                    prepend-icon="person"
                    name="username"
                    label="Gebruikersnaam"
                    type="text"
                    autocomplete="off"
                    variant="underlined"
                  />
                  <v-text-field
                    id="pincode"
                    v-model="pincode"
                    prepend-icon="lock"
                    name="pincode"
                    label="Pin"
                    type="password"
                    autocomplete="off"
                    variant="underlined"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn v-show="!loading" color="primary" depressed type="submit">
                    Inloggen
                  </v-btn>
                  <v-progress-circular v-show="loading" indeterminate color="primary" />
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import Say from '@/utils/Say'
import { useAuth } from '@eenvoud/eenvauth'
import axios from 'axios';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import * as Sentry from '@sentry/vue'

// If this page renders it means we are not logged in
Sentry.setUser(null)

const auth = useAuth()

const loading = ref(false)
const error = ref(null)
const username = ref('')
const pincode = ref('')
const router = useRouter()

const login = () => {
  loading.value = true
  auth.login({
    username: username.value,
    pincode: pincode.value,
  }).then(res => {
    axios.defaults.headers.common['Authorization'] = res.headers.authorization
    router.push('/')
  }, (err: any) => {
    loading.value = false
    error.value = err.response
    Say('error', err)
  })
}

</script>

<style scoped>
.card.card--flat {
  border: none;
}
.card__title {
  justify-content: center;
  padding: 5vh 0;
}
</style>
