<template>
  <v-footer fixed inset app class="footer" style="height: 45px" v-if="this.$route.name !== 'PickUpMaterials' && this.$route.name !== 'DropOffMaterials'">
    <v-row class="align-center justify-space-between" v-if="!workOrderApproved && !workOrder.is_in_pro_formas">
      <template v-if="workOrderOpen">
        <template v-if="actions">
          <v-btn v-if="actions.left" color="primary" rounded @click="handleLeftAction">
            {{ actions.left.text }}
          </v-btn>
        </template>

        <v-btn v-else color="primary" rounded @click.stop="openManageMaterialsModal">
          Beheer materiaal
        </v-btn>
      </template>
      <span v-else><!-- Force the middle part in the middle --></span>

      <div v-if="isDriving || isWorking || isDrivingBack">
        <div class="title">
          {{ elapsedTimeMessage }}
        </div>
      </div>
      <v-btn v-else-if="workOrderOpen" color="secondary" rounded @click="closeWorkOrder">
        Sluit werkorder
      </v-btn>
      <v-btn v-else-if="!workOrderClosed" color="secondary" rounded @click="reopenWorkOrder">
        Heropen werkorder
      </v-btn>

      <template v-if="workOrderOpen">
        <template v-if="actions">
          <v-btn :disabled="disabledBeforeEmit" v-if="actions.right" color="primary" rounded @click="handleRightAction">
            {{ actions.right.text }}
          </v-btn>
        </template>

        <template v-else>
          <router-link v-if="!isDriving && !isWorking && !isDrivingBack" :to="drivingRoute">
            <v-btn color="primary" rounded>
              Start met rijden
            </v-btn>
          </router-link>

          <v-btn v-else-if="isDriving" color="primary" rounded @click="updateWorkOrderOngoingStatus">
            Start met werken
          </v-btn>

          <v-btn v-else-if="isWorking" color="primary" rounded @click="updateWorkOrderOngoingStatus">
            Start met terugrijden
          </v-btn>

          <router-link v-else-if="isDrivingBack" :to="returnedRoute">
            <v-btn color="primary" rounded>
              Gearriveerd bij depot
            </v-btn>
          </router-link>
        </template>
      </template>
      <span v-else><!-- Force the middle part in the middle --></span>
    </v-row>
    <v-row class="align-center justify-space-between" v-else>
      <span></span>
      <span>Werkorder is goedgekeurd en kan niet meer worden aangepast.</span>
      <span></span>
    </v-row>

    <v-dialog v-model="showManageMaterialsModal" max-width="500px">
      <v-card>
        <v-card-text class="pb-6">
          <v-row class="flex-column align-center">
            <v-icon medium @click="closeManageMaterialsModal">
              mdi-close
            </v-icon>

            <div class="headline">
              Wat wil je doen?
            </div>

            <div>
              <router-link
                v-if="!workOrder.is_for_equipment_movement"
                :to="pickUpMaterialsRoute">
                <v-btn color="primary" @click="closeManageMaterialsModal" class="mr-4">
                  Materiaal ophalen
                </v-btn>
              </router-link>

              <router-link
                :to="dropOffMaterialsRoute">
                <v-btn color="primary" @click="closeManageMaterialsModal">
                  <template v-if="workOrder.is_for_equipment_movement && ( workOrder.category === 'Materiaal ophalen bij vestiging' || workOrder.category === 'Materiaal ophalen bij verhuur leverancier' )">
                    Materiaal ophalen
                  </template>
                  <template v-else="">
                    Materiaal plaatsen
                  </template>
                </v-btn>
              </router-link>
            </div>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-footer>
</template>

<script>
import {
  updateWorkOrderOngoingStatus,
  closeWorkOrder,
  reopenWorkOrder,
} from '@/api/workOrders'

import Say from '@/utils/Say'
import bus from '@/utils/eventBus'
import SayError from "@/utils/SayError";

export default {
  props: {
    workOrder: {
      type: Object,
      required: true,
    },
  },

  data() {
    const { id } = this.workOrder

    return {
      actions: null,
      disabledBeforeEmit: false,
      showManageMaterialsModal: false,

      dropOffMaterialsRoute: { name: 'DropOffMaterials', params: { id } },
      pickUpMaterialsRoute: { name: 'PickUpMaterials', params: { id } },

      drivingRoute: { name: 'StartDriving', params: { id } },
      returnedRoute: { name: 'WorkOrderReturned', params: { id } },

      relativeTimestamp: 0,
      currentTimestamp: 0,
      intervalRef: null,
    }
  },

  computed: {
    isDriving() {
      return this.workOrder.status_ongoing && this.workOrder.status_ongoing.name === 'DEPARTED'
    },

    isWorking() {
      return this.workOrder.status_ongoing && this.workOrder.status_ongoing.name === 'ARRIVED'
    },

    isDrivingBack() {
      return this.workOrder.status_ongoing && this.workOrder.status_ongoing.name === 'RETURNING'
    },

    timeElapsed() {
      const millisecondsElapsed = this.currentTimestamp - this.relativeTimestamp

      const hours = Math.floor(millisecondsElapsed / 3600000)
      const minutes = Math.floor((millisecondsElapsed % 3600000) / 60000)
      const days = Math.floor(hours / 24)

      const paddedHours = `${hours % 24}`.padStart(2, '0')
      const paddedMinutes = `${minutes}`.padStart(2, '0')

      let time = ''
      if (days === 1) {
        time += `1 dag, `
      } else if (days) {
        time += `${days} dagen, `
      }

      time += `${paddedHours}:${paddedMinutes} uur`

      return time
    },

    workOrderOpen() {
      return ['DRAFT', 'PLANNED', 'ONGOING'].includes(this.workOrder.status.name)
    },

    workOrderClosed() {
      return this.workOrder.status.name === 'CLOSED'
    },

    workOrderApproved() {
      return this.workOrder.status.name === 'APPROVED'
    },

    elapsedTimeMessage() {
      if (this.isWorking) {
        return `Werken: ${this.timeElapsed}`
      }
      if (this.isDriving || this.isDrivingBack) {
        return `Onderweg: ${this.timeElapsed}`
      }

      return ''
    },
  },

  watch: {
    workOrder: {
      deep: true,
      immediate: true,
      handler(workOrder) {
        if (workOrder && workOrder.last_time) {
          this.relativeTimestamp = Date.parse(workOrder.last_time)
          this.startTimer()
        } else {
          this.stopTimer()
        }
      },
    },
  },

  created() {
    bus.on('work-order-footer:set-actions', this.setActions)
    bus.on('work-order-footer:clear-actions', this.clearActions)
    bus.on('disable-save-button', () => this.disabledBeforeEmit = true)
    bus.on('enable-save-button', () => this.disabledBeforeEmit = false)
  },

  beforeUnmount() {
    bus.off('work-order-footer:set-actions', this.setActions)
    bus.off('work-order-footer:clear-actions', this.clearActions)

    this.stopTimer()
  },

  methods: {
    openManageMaterialsModal() {
      this.disabledBeforeEmit = false
      this.showManageMaterialsModal = true
    },

    closeManageMaterialsModal() {
      this.showManageMaterialsModal = false
    },

    setActions(actions) {
      this.actions = actions
    },

    clearActions() {
      this.actions = null
    },

    async updateWorkOrderOngoingStatus() {
      // Fetch the latest work order data
      await this.$store.dispatch('workOrders.fetchSingle', this.workOrder.id)

      updateWorkOrderOngoingStatus(this.workOrder.id, {
        people: this.workOrder.employees.map(({ id }) => id),
      }).then(({ data }) => {
        const { status, statusOngoing } = data

        this.$store.commit('workOrders.setView', {
          ...this.workOrder,
          status,
          status_ongoing: statusOngoing,
          'last_time': (new Date()).toISOString(),
        })
      }).catch((err) => {
        Say('error', err)
      })
    },

    startTimer() {
      this.stopTimer()
      this.updateCurrentTimestamp()

      this.intervalRef = window.setInterval(this.updateCurrentTimestamp, 1000)
    },

    stopTimer() {
      if (this.intervalRef) {
        window.clearInterval(this.intervalRef)
        this.intervalRef = null
      }
    },

    updateCurrentTimestamp() {
      this.currentTimestamp = Date.now()
    },

    closeWorkOrder() {
      closeWorkOrder(this.workOrder.id).then(({ data }) => {
        this.$store.commit('workOrders.setView', { ...this.workOrder, status: data.status })
      }).catch(error => Say('error', error))
    },

    reopenWorkOrder() {
      reopenWorkOrder(this.workOrder.id).then(({ data }) => {
        this.$store.commit('workOrders.setView', { ...this.workOrder, status: data.status })
      }).catch(error => Say('error', error))
    },

    handleLeftAction() {
      bus.emit(this.actions.left.event)
    },

    handleRightAction() {
      bus.emit(this.actions.right.event)
    }
  },
}
</script>

<style lang="scss" scoped>
.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f5f5f5;
}

a {
  text-decoration: none;
}

.v-card__text {
  position: relative;
}

.v-icon {
  position: absolute;
  top: 8px;
  right: 8px;
}

.headline {
  margin-bottom: 8px;
  font-size: 24px;
}
</style>
